<template>
  <div
    v-if="!configUI['project-images-tab']"
    class="box max-w-640 mx-auto mt-6"
  >
    <h2 class="mb-3">
      {{ $t('access-denied') }}
    </h2>
    <p>{{ $t('insufficient-permission') }}</p>
  </div>
  <div v-else-if="error" class="box max-w-640 mx-auto mt-6">
    <h2 class="mb-3">
      {{ $t('error') }}
    </h2>
    <p>{{ $t('unexpected-error-info-message') }}</p>
  </div>
  <div v-else class="content-wrapper">
    <div v-if="isArchived" class="mb-2 radius-4 p-2 bg-red color-white">
      {{ $t('project-archived-notice') }}
    </div>

    <BLoading v-if="loading" :is-full-page="false" active />

    <IdxPanel v-else>
      <template #header>
        <div class="flex align-center justify-between gap-8 flex-wrap">
          <h2 class="m-0 size-20 weight-4 text-transform-none spacing-0">
            {{ $t('images') }}
          </h2>
          <span v-if="isSuperAdmin && trainingModels.length">
            <label class="visually-hidden" for="select-training">
              {{ $t('training-launch') }}
            </label>
            <BSelect
              id="select-training"
              v-model="selectedTrainingModel"
              style="display:inline-block;"
            >
              <option
                v-for="model in trainingModels"
                :key="model"
                :value="model"
              >
                {{ model }}
              </option>
            </BSelect>
            <IdxBtn
              class="ml-2"
              :color="checkedRows.length ? 'primary' : ''"
              :disabled="!selectedTrainingModel || !checkedRows.length"
              data-label="LaunchTrainingBtn"
              @click="launchTrainingModal"
            >
              {{ $t('training-launch') }}
            </IdxBtn>
          </span>
          <form
            v-if="isAdmin && !project.isClosed && availableAssays.length"
            class="flex gap-8 flex-wrap"
            @submit.prevent="launchJob"
          >
            <label class="visually-hidden" for="select-algorithm">
              {{ $t('algorithm') }}
            </label>
            <BSelect id="select-algorithm" v-model="algorithmSelected">
              <option
                v-for="option in availableAssays"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }} (v{{ option.fileVersion }})
              </option>
            </BSelect>
            <IdxBtn
              :color="algorithmSelected && checkedRows.length ? 'primary' : ''"
              :disabled="!algorithmSelected || !checkedRows.length"
              data-label="LaunchAlgorithmBtn"
              @click="launchJobModal"
            >
              {{ $t('algorithm-launch') }}
            </IdxBtn>
          </form>

          <div v-if="canManageProject" class="flex gap-8">
            <IdxBtn
              v-if="canEditProject && !project.lockImageSet"
              color="blue"
              data-label="AddImgBtn"
              @click="addImageModal = true"
            >
              {{ $t('add') }}
            </IdxBtn>

            <!-- eslint-disable vuejs-accessibility/aria-props -->
            <BDropdown aria-role="list" position="is-bottom-left">
              <template #trigger="{ active }">
                <BButton
                  :label="$t('actions')"
                  :icon-right="active ? 'angle-up' : 'angle-down'"
                />
              </template>

              <BDropdownItem
                aria-role="listitem"
                class="flex align-center"
                :disabled="!checkedRows.length"
                @click="triggerDownload(checkedRows)"
              >
                <BIcon icon="cloud-download-alt" class="mr-2" />
                {{ $t('download') }}
              </BDropdownItem>

              <BDropdownItem
                v-if="canEditProject && !project.lockImageSet"
                aria-role="listitem"
                class="flex align-center"
                :disabled="!checkedRows.length"
                @click="deleteImages(checkedRows)"
              >
                <BIcon icon="trash" class="mr-2" />
                {{ $t('delete') }}
              </BDropdownItem>

              <BDropdownItem
                v-if="isAdmin"
                aria-role="listitem"
                class="flex align-center"
                :disabled="!checkedRows.length"
                @click="copyToProject(checkedRows)"
              >
                <BIcon icon="clone" class="mr-2" />
                {{ $t('clone-images') }}
              </BDropdownItem>

              <BDropdownItem
                v-if="isAdmin"
                aria-role="listitem"
                class="flex align-center"
                :disabled="!checkedRows.length"
                @click="() => (showAnnotationCloneModal = true)"
              >
                <BIcon icon="clone" class="mr-2" />
                {{ $t('clone-annotations') }}
              </BDropdownItem>
              <BDropdownItem
                v-if="isAdmin"
                aria-role="listitem"
                class="flex align-center"
                @click="isVisibleChannelRenameModal = true"
              >
                <BIcon icon="fas fa-sliders-h" class="mr-2" />
                {{ $t('channels-rename') }}
              </BDropdownItem>
            </BDropdown>
            <!-- eslint-enable vuejs-accessibility/aria-props -->
          </div>
        </div>
      </template>

      <div class="flex">
        <BInput
          v-model="searchString"
          :placeholder="$t('search')"
          class="mr-4"
          data-label="ImgSearch"
          type="search"
          icon="search"
        />
        <!-- eslint-disable vuejs-accessibility/aria-props -->
        <BDropdown aria-role="list" :close-on-click="false" class="mr-4">
          <template #trigger="{ active }">
            <BButton :icon-right="active ? 'angle-up' : 'angle-down'">
              <i class="fas fa-cog" /> {{ $t('columns') }}
            </BButton>
          </template>

          <BDropdownItem
            v-for="column in selectableImageColumns"
            :key="column.label"
            aria-role="listitem"
            @click="column.visible = !column.visible"
          >
            <BCheckbox :value="column.visible" style="pointer-events:none;">
              {{ $t(column.label) }}
            </BCheckbox>
          </BDropdownItem>
        </BDropdown>
        <IdxBtn
          class="mr-4"
          data-label="OpenFiltersBtn"
          @click="filtersOpened = !filtersOpened"
        >
          <span class="icon">
            <i class="fas fa-filter" />
          </span>
          <span>
            {{ filtersOpened ? $t('filters-hide') : $t('filters-show') }}
          </span>
          <span v-if="numberOfActiveFilters" class="nb-active-filters">
            {{ numberOfActiveFilters }}
          </span>
        </IdxBtn>

        <IdxBtn
          v-if="numberOfActiveFilters > 0"
          class="ml-4"
          :link="true"
          :small="true"
          @click="resetFiltersToDefault"
        >
          Reset to default
        </IdxBtn>
      </div>

      <BCollapse :open="filtersOpened">
        <div class="mt-3 radius-12 p-4 bg-gray-1">
          <div class="columns">
            <div class="column is-one-quarter">
              <div class="mb-1">
                {{ $t('format') }}
              </div>
              <CytomineMultiselect
                v-model="selectedFormats"
                :options="availableFormats"
                multiple
                data-label="FormatSelector"
              />
            </div>

            <div class="column is-one-quarter">
              <div class="mb-1">
                {{ $t('vendor') }} & {{ $t('media-type') }}
              </div>
              <CytomineMultiselect
                v-model="selectedVendors"
                :options="availableVendors"
                multiple
                label="label"
                track-by="value"
                data-label="VendorSelector"
              />
            </div>

            <div class="column is-one-quarter">
              <div class="mb-1">
                {{ $t('status') }}
              </div>
              <CytomineMultiselect
                v-model="selectedStatus"
                :options="availableStatus"
                multiple
                label="label"
                track-by="value"
                data-label="statusSelector"
              />
            </div>

            <!-- Tags hidden until scoped access per user per project -->
            <!-- <div class="column is-one-quarter">
              <div class="mb-1">
                {{ $t('tags') }}
              </div>
              <CytomineMultiselect
                v-model="selectedTags"
                :options="availableTags"
                multiple
                :all-placeholder="$t('all')"
                label="name"
                track-by="id"
              />
            </div> -->
          </div>

          <div class="columns">
            <div class="column">
              <div class="mb-1">
                {{ $t('magnification') }}
              </div>
              <CytomineMultiselect
                v-model="selectedMagnifications"
                :options="availableMagnifications"
                multiple
                :searchable="false"
                label="label"
                track-by="value"
                data-label="MagnificationSelector"
              />
            </div>

            <div class="column">
              <div class="mb-1">
                {{ $t('resolution') }}
              </div>
              <CytomineMultiselect
                v-model="selectedResolutions"
                :options="availableResolutions"
                multiple
                :searchable="false"
                label="label"
                track-by="value"
                data-label="ResolutionSelector"
              />
            </div>

            <div class="column">
              <div class="mb-1">
                {{ $t('width') }}
              </div>
              <CytomineSlider
                v-model="boundsWidth"
                :max="maxWidth"
                :tooltip-direction="['left', 'right']"
                data-label="ImgWidthSlider"
              />
            </div>

            <div class="column">
              <div class="mb-1">
                {{ $t('height') }}
              </div>
              <CytomineSlider
                v-model="boundsHeight"
                :max="maxHeight"
                :tooltip-direction="['left', 'right']"
                data-label="ImgHeightSlider"
              />
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="mb-1">
                {{ $t('user-annotations') }}
              </div>
              <CytomineSlider
                v-model="boundsUserAnnotations"
                :max="maxNbUserAnnotations"
                :tooltip-direction="['left', 'right']"
                data-label="UserAnnotationsSlider"
              />
            </div>

            <div class="column">
              <div class="mb-1">
                {{ $t('analysis-annotations') }}
              </div>
              <CytomineSlider
                v-model="boundsJobAnnotations"
                :max="maxNbJobAnnotations"
                :tooltip-direction="['left', 'right']"
                data-label="JobAannotationsSlider"
              />
            </div>

            <div class="column">
              <div class="mb-1">
                {{ $t('reviewed-annotations') }}
              </div>
              <CytomineSlider
                v-model="boundsReviewedAnnotations"
                :max="maxNbReviewedAnnotations"
                :tooltip-direction="['left', 'right']"
                data-label="ReviewedAnnotationsSlider"
              />
            </div>

            <div class="column" />
          </div>
        </div>
      </BCollapse>

      <IdxTable
        :data="images"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :await="request"
        detailed
        :opened-detailed.sync="openedDetails"
        :sort-by.sync="sortBy"
        :sort-direction.sync="sortDirection"
        checkable
        :checked-rows.sync="checkedRows"
      >
        <template #default>
          <BTableColumn v-slot="props" :label="$t('overview')" width="100">
            <RouterLink
              :to="`/project/${props.row.project}/image/${props.row.id}`"
            >
              <img
                :src="props.row.thumb.replace(512, 256)"
                class="max-w-160 max-h-64 size-12 overflow-auto word-break-all"
                :alt="props.row.instanceFilename"
              />
            </RouterLink>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('name')"
            :field="blindMode ? 'blindedName' : 'instanceFilename'"
            :label="$t('name')"
            sortable
            class="word-break-all min-w-320"
          >
            <RouterLink
              :to="`/project/${props.row.project}/image/${props.row.id}`"
            >
              <ImageName :image="props.row" show-both-names />
            </RouterLink>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('custom-assay-status')"
            field="customAssayRunStatus"
            :label="$t('custom-assay-status')"
            centered
            width="100"
          >
            <StatusMapping
              v-if="props.row.customAssayRunStatus"
              :key="props.row.id"
              :status="props.row.customAssayRunStatus"
            />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('focus-status')"
            field="focusAssayRunStatus"
            :label="$t('focus-status')"
            centered
            width="100"
          >
            <StatusMapping
              v-if="props.row.focusAssayRunStatus"
              :key="props.row.id"
              :status="props.row.focusAssayRunStatus"
            />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('qc')"
            field="qc"
            :label="$t('qc')"
            centered
            width="100"
          >
            <QcToggle :key="props.row.id" :image="props.row" />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('results')"
            field="data-field"
            :label="resultsHeaderProp.value || $t('results')"
            centered
            width="150"
          >
            <DataField
              :key="props.row.id"
              :image="props.row"
              :max="Number(resultsMaxProp.value) || 100"
            />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('magnification')"
            :label="$t('magnification')"
            field="magnification"
            centered
            sortable
            width="100"
          >
            {{ props.row.magnification || $t('unknown') }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('user-annotations')"
            :label="$t('user-annotations')"
            field="numberOfAnnotations"
            centered
            sortable
            width="100"
          >
            <RouterLink
              :to="
                `/project/${props.row.project}/annotations?image=${props.row.id}&type=user`
              "
            >
              {{ props.row.numberOfAnnotations }}
            </RouterLink>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('status-last-update')"
            field="lastUpdated"
            :label="$t('status-last-update')"
            sortable
            centered
            width="100"
          >
            {{
              props.row.lastUpdated ? props.row.lastUpdated.toUTCString() : '-'
            }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('analysis-annotations')"
            :label="$t('analysis-annotations')"
            field="numberOfJobAnnotations"
            centered
            sortable
            width="100"
          >
            <RouterLink
              :to="
                `/project/${props.row.project}/annotations?image=${props.row.id}&type=algo`
              "
            >
              {{ props.row.numberOfJobAnnotations }}
            </RouterLink>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('reviewed-annotations')"
            :label="$t('reviewed-annotations')"
            field="numberOfReviewedAnnotations"
            centered
            sortable
            width="100"
          >
            <RouterLink
              :to="
                `/project/${props.row.project}/annotations?image=${props.row.id}&type=reviewed`
              "
            >
              {{ props.row.numberOfReviewedAnnotations }}
            </RouterLink>
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :visible="isColumnShown('created')"
            field="created"
            :label="$t('created')"
            centered
            sortable
            width="100"
          >
            {{ new Date(Number(props.row.created)).toUTCString() }}
          </BTableColumn>

          <BTableColumn v-slot="props" label="" centered width="100">
            <IdxBtn
              :to="`/project/${props.row.project}/image/${props.row.id}`"
              small
              color="primary"
            >
              {{ $t('open') }}
            </IdxBtn>
          </BTableColumn>
        </template>

        <template #detail="{ row: image }">
          <ImageDetails
            :image="image"
            :excluded-properties="excludedProperties"
            editable
            @delete="refreshData"
            @setResolution="refreshData"
            @setMagnification="refreshData"
            @review-canceled="onReviewCanceled(image)"
          />
        </template>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-image') }}</p>
          </div>
        </template>
      </IdxTable>

      <AddImageModal :active.sync="addImageModal" @addImage="refreshData" />
      <ChannelRenameModal
        :active.sync="isVisibleChannelRenameModal"
        :data="checkedRows"
      />
      <BulkAnnotationCopyModalVue
        :active.sync="showAnnotationCloneModal"
        :selected-images="checkedRows"
        :id-project="idProject"
      />

      <LaunchJobModal
        :active.sync="openJobModal"
        :assay-params="assayParams"
        :algorithm-selected="algorithmSelected"
        :selected-img-ids="selectedImgIds"
        :id-project="idProject"
        :revision.sync="revision"
      />
      <LaunchV2JobModal
        :active.sync="openV2JobModal"
        :assay-params="v2AssayParams"
        :algorithm-selected="algorithmSelected"
        :selected-img-ids="selectedImgIds"
        :id-project="idProject"
        :revision.sync="revision"
      />
      <LaunchTrainingModal
        :active.sync="openTrainingModal"
        :selected-training-model="selectedTrainingModel"
        :selected-images="checkedRows"
        :id-project="idProject"
        :revision.sync="revision"
      />
    </IdxPanel>
  </div>
</template>

<script>
import debounce from 'lodash/debounce.js';
import noteApi, { downloadImages } from '../../services/noteApi.js';
import { GetTrainingModels } from '../../services/trainingFramework';
import vendorFromMime from '../../utils/vendor.js';
import {
  DataField,
  QcToggle,
  StatusMapping,
  IdxTable,
} from '../utils/index.js';
import { CytomineMultiselect, CytomineSlider } from '../form/index.js';
import BulkAnnotationCopyModalVue from './BulkAnnotationCopyModal.vue';
import {
  ImageName,
  ImageDetails,
  AddImageModal,
  ChannelRenameModal,
  LaunchJobModal,
  LaunchV2JobModal,
  LaunchTrainingModal,
} from './index.js';

const DEFAULT_SEARCH_STRING = '';
const DEFAULT_SELECTED_FORMATS = [];
const DEFAULT_SELECTED_VENDORS = [];
const DEFAULT_SELECTED_STATUS = [];
const DEFAULT_SELECTED_MAGNIFICATIONS = [];
const DEFAULT_SELECTED_RESOLUTIONS = [];
const DEFAULT_SELECTED_TAGS = [];
const DEFAULT_BOUNDS_WIDTH = [0, 100];
const DEFAULT_BOUNDS_HEIGHT = [0, 100];
const DEFAULT_BOUNDS_USER_ANNOTATIONS = [0, 100];
const DEFAULT_BOUNDS_JOB_ANNOTATIONS = [0, 100];
const DEFAULT_BOUNDS_REVIEWED_ANNOTATIONS = [0, 100];
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PER_PAGE = 10;
const DEFAULT_SORT_BY = '';
const DEFAULT_SORT_DIRECTION = '';

function imagesWatcher() {
  // @ts-ignore
  this.request = this.fetchImages();

  // save filter values to local storage
  localStorage.setItem(this.idProject + '_perPage', this.perPage);
  localStorage.setItem(this.idProject + '_currentPage', this.currentPage);
  localStorage.setItem(this.idProject + '_sortBy', this.sortBy);
  localStorage.setItem(this.idProject + '_sortDirection', this.sortDirection);
  localStorage.setItem(this.idProject + '_searchString', this.searchString);
  localStorage.setItem(
    this.idProject + '_selectedFormats',
    JSON.stringify(this.selectedFormats)
  );
  localStorage.setItem(
    this.idProject + '_selectedVendors',
    JSON.stringify(this.selectedVendors)
  );
  localStorage.setItem(
    this.idProject + '_selectedStatus',
    JSON.stringify(this.selectedStatus)
  );
  localStorage.setItem(
    this.idProject + '_selectedMagnifications',
    JSON.stringify(this.selectedMagnifications)
  );
  localStorage.setItem(
    this.idProject + '_selectedResolutions',
    JSON.stringify(this.selectedResolutions)
  );
  localStorage.setItem(
    this.idProject + '_selectedTags',
    JSON.stringify(this.selectedTags)
  );
  localStorage.setItem(
    this.idProject + '_boundsWidth',
    JSON.stringify(this.boundsWidth)
  );
  localStorage.setItem(
    this.idProject + '_boundsHeight',
    JSON.stringify(this.boundsHeight)
  );
  localStorage.setItem(
    this.idProject + '_boundsUserAnnotations',
    JSON.stringify(this.boundsUserAnnotations)
  );
  localStorage.setItem(
    this.idProject + '_boundsJobAnnotations',
    JSON.stringify(this.boundsJobAnnotations)
  );
  localStorage.setItem(
    this.idProject + '_boundsReviewedAnnotations',
    JSON.stringify(this.boundsReviewedAnnotations)
  );
}

/** @type {import('vue').ComponentOptions} */
const deleteImagesMixin = {
  methods: {
    /** @param {Array<{ id: number }>} checkedRows */
    async deleteImages(checkedRows) {
      if (!checkedRows.length) return;

      try {
        await noteApi.delete(`/napi/image`, {
          json: {
            imageIds: checkedRows.map((img) => img.id),
          },
        });
        this.revision++;
      } catch (error) {
        this.$notify({
          type: 'error',
          text: this.$t('unexpected-error-info-message'),
        });
      }
    },
  },
};

export default {
  name: 'ListImages',
  components: {
    BulkAnnotationCopyModalVue,
    ImageName,
    ImageDetails,
    CytomineMultiselect,
    CytomineSlider,
    IdxTable,
    AddImageModal,
    ChannelRenameModal,
    LaunchJobModal,
    LaunchV2JobModal,
    LaunchTrainingModal,
    QcToggle,
    DataField,
    StatusMapping,
  },
  mixins: [deleteImagesMixin],
  props: {
    idProject: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      /** @type {Array<{assayId: number, projectId: number, name: string, fileVersion: number}>} */
      availableAssays: [],
      algorithmSelected: '',
      selectedImgIds: [],
      selectedTrainingModel: '',
      addImageModal: false,
      isVisibleChannelRenameModal: false,
      filtersOpened: false,
      showAnnotationCloneModal: false,

      searchString:
        localStorage.getItem(this.idProject + '_searchString') ||
        DEFAULT_SEARCH_STRING,
      availableFormats: [],
      selectedFormats:
        JSON.parse(localStorage.getItem(this.idProject + '_selectedFormats')) ||
        DEFAULT_SELECTED_FORMATS,
      availableVendors: [],
      selectedVendors:
        JSON.parse(localStorage.getItem(this.idProject + '_selectedVendors')) ||
        DEFAULT_SELECTED_VENDORS,
      availableStatus: [],
      selectedStatus:
        JSON.parse(localStorage.getItem(this.idProject + '_selectedStatus')) ||
        DEFAULT_SELECTED_STATUS,
      availableMagnifications: [],
      selectedMagnifications:
        JSON.parse(
          localStorage.getItem(this.idProject + '_selectedMagnifications')
        ) || DEFAULT_SELECTED_MAGNIFICATIONS,
      availableResolutions: [],
      selectedResolutions:
        JSON.parse(
          localStorage.getItem(this.idProject + '_selectedResolutions')
        ) || DEFAULT_SELECTED_RESOLUTIONS,
      availableTags: [],
      selectedTags:
        JSON.parse(localStorage.getItem(this.idProject + '_selectedTags')) ||
        DEFAULT_SELECTED_TAGS,
      maxWidth: 100,
      /** @type {[number, number]} */
      boundsWidth:
        JSON.parse(localStorage.getItem(this.idProject + '_boundsWidth')) ||
        DEFAULT_BOUNDS_WIDTH,
      maxHeight: 100,
      /** @type {[number, number]} */
      boundsHeight:
        JSON.parse(localStorage.getItem(this.idProject + '_boundsHeight')) ||
        DEFAULT_BOUNDS_HEIGHT,
      maxNbUserAnnotations: 100,
      /** @type {[number, number]} */
      boundsUserAnnotations:
        JSON.parse(
          localStorage.getItem(this.idProject + '_boundsUserAnnotations')
        ) || DEFAULT_BOUNDS_USER_ANNOTATIONS,
      maxNbJobAnnotations: 100,
      /** @type {[number, number]} */
      boundsJobAnnotations:
        JSON.parse(
          localStorage.getItem(this.idProject + '_boundsJobAnnotations')
        ) || DEFAULT_BOUNDS_JOB_ANNOTATIONS,
      maxNbReviewedAnnotations: 100,
      /** @type {[number, number]} */
      boundsReviewedAnnotations:
        JSON.parse(
          localStorage.getItem(this.idProject + '_boundsReviewedAnnotations')
        ) || DEFAULT_BOUNDS_REVIEWED_ANNOTATIONS,

      total: 0,
      currentPage:
        parseInt(localStorage.getItem(this.idProject + '_currentPage')) ||
        DEFAULT_CURRENT_PAGE,
      perPage:
        parseInt(localStorage.getItem(this.idProject + '_perPage')) ||
        DEFAULT_PER_PAGE,
      sortBy:
        localStorage.getItem(this.idProject + '_sortBy') || DEFAULT_SORT_BY,
      sortDirection:
        localStorage.getItem(this.idProject + '_sortBy') ||
        DEFAULT_SORT_DIRECTION,

      request: null,
      images: [],
      checkedRows: [],
      openedDetails: [],
      excludedProperties: [
        'overview',
        'instanceFilename',
        'magnification',
        'numberOfAnnotations',
        'numberOfJobAnnotations',
        'numberOfReviewedAnnotations',
      ],
      imageColumns: [],
      isArchived: false,
      revision: 0,
      // Project Props for Results Column
      resultsHeaderProp: {},
      resultsMaxProp: {},
      assayParams: {},
      v2AssayParams: [],
      openJobModal: false,
      openV2JobModal: false,
      openTrainingModal: false,
      trainingModels: [],
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {boolean} */
    blindMode() {
      return this.project.blindMode;
    },
    /** @returns {boolean} */
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    /** @returns {boolean} */
    isAdmin() {
      return this.currentUser.admin;
    },
    isSuperAdmin() {
      return this.currentUser.adminByNow;
    },
    /** @returns {boolean} */
    canEditProject() {
      return (
        this.canManageProject &&
        !this.project.isReadOnly &&
        !this.project.isClosed
      );
    },
    /** @returns {Map<string, {selected: any[], total: number}>} */
    multiSelectFilters() {
      const map = new Map();
      map.set('extension', {
        selected: this.selectedFormats,
        total: this.availableFormats.length,
      });
      map.set('mimeType', {
        selected: this.selectedVendors.map((option) => option.value),
        total: this.availableVendors.length,
      });
      map.set('customAssayRunStatus', {
        selected: this.selectedStatus.map((option) => option.value),
        total: this.availableStatus.length,
      });
      map.set('magnification', {
        selected: this.selectedMagnifications.map((option) => option.value),
        total: this.availableMagnifications.length,
      });
      map.set('resolution', {
        selected: this.selectedResolutions.map((option) => option.value),
        total: this.availableResolutions.length,
      });
      map.set('tag', {
        selected: this.selectedTags.map((option) => option.id),
        total: this.availableTags.length,
      });
      return map;
    },
    /** @returns {Map<string, [number, number]>} */
    boundsFilters() {
      return new Map([
        ['numberOfAnnotations', this.boundsUserAnnotations],
        ['numberOfJobAnnotations', this.boundsJobAnnotations],
        ['numberOfReviewedAnnotations', this.boundsReviewedAnnotations],
        ['width', this.boundsWidth],
        ['height', this.boundsHeight],
      ]);
    },
    /** @returns {number} */
    numberOfActiveFilters() {
      let count = 0;

      // For multiselect filters, check if at least one value is active
      for (const filter of [
        'selectedFormats',
        'selectedVendors',
        'selectedStatus',
        'selectedMagnifications',
        'selectedResolutions',
        //'selectedTags',
      ]) {
        if (this[filter].length > 0) count++;
      }

      // For bounds filters, check if min is greater than 0 or max is less than absolute max
      for (const [filterKey, maxKey] of [
        ['boundsWidth', 'maxWidth'],
        ['boundsHeight', 'maxHeight'],
        ['boundsUserAnnotations', 'maxNbUserAnnotations'],
        ['boundsJobAnnotations', 'maxNbJobAnnotations'],
        ['boundsReviewedAnnotations', 'maxNbReviewedAnnotations'],
      ]) {
        const [selectedMin, selectedMax] = this[filterKey];
        const filterMax = this[maxKey];

        if (selectedMin > 0 || selectedMax < filterMax) count++;
      }

      return count;
    },

    selectableImageColumns() {
      return this.imageColumns.filter((c) => c.selectable);
    },
  },

  watch: {
    searchString: debounce(imagesWatcher, 500),
    selectedFormats: imagesWatcher,
    selectedVendors: imagesWatcher,
    selectedStatus: imagesWatcher,
    selectedTags: imagesWatcher,
    selectedMagnifications: imagesWatcher,
    selectedResolutions: imagesWatcher,
    boundsWidth: imagesWatcher,
    boundsHeight: imagesWatcher,
    boundsUserAnnotations: imagesWatcher,
    boundsJobAnnotations: imagesWatcher,
    boundsReviewedAnnotations: imagesWatcher,
    currentPage: imagesWatcher,
    perPage: imagesWatcher,
    sortBy: imagesWatcher,
    sortDirection: imagesWatcher,
    revision: imagesWatcher,
  },
  async created() {
    try {
      const project = this.project;
      this.loading = true;

      // Get training models
      this.trainingModels = await GetTrainingModels();

      // Trigger archive restore in case this project is archived
      noteApi.post(`/napi/project/${project.id}/archive-restore`);

      const [results, archiveStatus] = await Promise.all([
        noteApi.get(
          `/api/domain/be.cytomine.project.Project/${project.id}/property.json?max=0&offset=0`
        ),
        noteApi.get(`/napi/project/${project.id}/archive-status`),
        this.fetchFilters(),
        this.fetchTags(),
      ]);

      const availableAssaysResult = await noteApi.get(
        `/napi/project/${this.project.id}/algorithm`
      );
      this.availableAssays = availableAssaysResult.map((assay) => {
        return {
          id: assay.assayId,
          name: assay.name,
          fileVersion: assay.fileVersion,
        };
      });

      this.getResultsProperties(results.collection);

      this.isArchived = archiveStatus.archived;

      this.request = this.fetchImages();

      // Set image columns
      this.imageColumns = [
        {
          selectable: true,
          visible: true,
          label: 'name',
        },
        {
          selectable: this.isTabDisplayed('custom-assay-status'),
          visible: true,
          label: 'custom-assay-status',
        },
        {
          selectable: this.isTabDisplayed('focus-assay-status'),
          visible: true,
          label: 'focus-status',
        },
        {
          selectable: this.isTabDisplayed('qc'),
          visible: true,
          label: 'qc',
        },
        {
          selectable: this.isTabDisplayed('data'),
          visible: true,
          label: 'results',
        },
        {
          selectable: this.isAdmin,
          visible: false,
          label: 'magnification',
        },
        {
          selectable: this.isTabDisplayed('manual-annotations'),
          visible: true,
          label: 'user-annotations',
        },
        {
          selectable:
            this.isAdmin &&
            (this.isTabDisplayed('focus-assay-status') ||
              this.isTabDisplayed('custom-assay-status')),
          visible: true,
          label: 'status-last-update',
        },
        {
          selectable: this.isAdmin,
          visible: false,
          label: 'analysis-annotations',
        },
        {
          selectable: this.isAdmin,
          visible: false,
          label: 'reviewed-annotations',
        },
        {
          selectable: true,
          visible: true,
          label: 'created',
        },
      ];
    } catch (error) {
      console.log(error);
      this.error = true;
    } finally {
      this.loading = false;
      if (!this.isTabDisplayed('image-slide-label')) {
        this.excludedProperties.push('slideLabel');
      } else if (this.excludedProperties.indexOf('slideLabel')) {
        this.excludedProperties.splice(
          this.excludedProperties.indexOf('slideLabel'),
          1
        );
      }
    }
  },

  methods: {
    async launchJobModal() {
      try {
        var assayJson = await noteApi.get(
          `napi/algorithm/${this.algorithmSelected}/download`
        );
        const params = assayJson.params || assayJson.param_values;
        if (Object.keys(params).length == 0) {
          this.launchJob();
        } else {
          this.selectedImgIds = this.checkedRows.map((img) => img.id);

          if (assayJson.param_values) {
            // v1 assay
            this.assayParams = params;
            this.openJobModal = true;
          } else {
            // v2 assay
            this.v2AssayParams = params;
            this.openV2JobModal = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async launchTrainingModal() {
      this.selectedImgIds = this.checkedRows.map((img) => img.id);
      this.openTrainingModal = true;
    },

    async getResultsProperties(projectProperties) {
      const resultsHeaderProperty = projectProperties.find(
        (prop) => prop.key === '@results_header'
      );
      if (resultsHeaderProperty) {
        this.resultsHeaderProp = resultsHeaderProperty;
      }
      const resultsMaxProperty = projectProperties.find(
        (prop) => prop.key === '@results_max'
      );
      if (resultsMaxProperty) {
        this.resultsMaxProp = resultsMaxProperty;
      }
    },
    async fetchImages() {
      const {
        searchString,
        currentPage,
        perPage,
        sortBy,
        sortDirection,
      } = this;
      const query = {
        max: perPage,
        offset: (currentPage - 1) * perPage,
      };

      this.multiSelectFilters.forEach(({ selected, total }, prop) => {
        if (selected.length > 0 && selected.length < total) {
          query[`${prop}[in]`] = selected.join();
        }
      });
      this.boundsFilters.forEach((bounds, prop) => {
        query[`${prop}[gte]`] = bounds[0];
        query[`${prop}[lte]`] = bounds[1];
      });

      if (searchString) {
        query['name[ilike]'] = encodeURIComponent(searchString);
      }

      if (sortBy) {
        query.sort = sortBy;
        query.order = sortDirection || 'asc';
      }

      const statusFilter = this.multiSelectFilters.get('customAssayRunStatus')
        .selected;

      if (statusFilter.length > 0) {
        query.max = 0;
        query.offset = 0;
      }
      const imageResults = await noteApi.get(
        `/api/project/${this.project.id}/imageinstance.json`,
        {
          query,
        }
      );
      const imageIds = imageResults.collection.map((data) => data.id);
      /**@type {[{key: String, status: number, created: Date}]} */
      // @ts-ignore
      const jobStatusRequest = noteApi.get(
        `napi/job/image-status?imageIds=${imageIds}`
      );

      // if job filtering is used, load all the props for images with jobs
      const allImageProperties = await noteApi.get(
        `/napi/project/${this.project.id}/image?properties=true&descriptions=false&perPage=-1`
      );

      const propertyRequests = imageResults.collection.map((img) => {
        const imgProperties = allImageProperties?.results.find(
          (p) => p.id === img.id
        )?.properties;
        return {
          ...img,
          properties: imgProperties,
        };
      });

      const [jobStatusResults, imagesWithProps] = await Promise.all([
        jobStatusRequest,
        Promise.all(propertyRequests),
      ]);

      const finalImages = imagesWithProps.map((img) => {
        const imgInfo = jobStatusResults.filter((job) => job.key == img.id);
        if (imgInfo && imgInfo.length) {
          img.customAssayRunStatus =
            imgInfo.find((a) => a.assayType === 'custom')?.status ?? 0;
          img.focusAssayRunStatus =
            imgInfo.find((a) => a.assayType === 'focus')?.status ?? 0;
          img.lastUpdated = new Date(imgInfo[0].created);
        }

        return img;
      });
      if (statusFilter.length > 0) {
        // If cancel filter is selected, cancel status includes status codes of 8 & 10
        if (statusFilter.includes(8)) {
          statusFilter.push(10);
        }
        const assayImages = finalImages.filter(
          (img) => statusFilter.indexOf(img.customAssayRunStatus) != -1
        );
        this.images = assayImages;
        this.total = this.images.size;
      } else {
        this.images = finalImages;
        this.total = imageResults.size;
      }
    }, //END

    async launchJob() {
      const { idProject, algorithmSelected, checkedRows } = this;
      if (!algorithmSelected || !checkedRows.length) return;

      try {
        this.$notify({
          type: 'success',
          text: this.$t('algorithm-run-start'),
        });
        await noteApi.post(`napi/algorithm/${algorithmSelected}/run`, {
          data: {
            projectId: idProject,
            selectedImgIds: checkedRows.map((img) => img.id),
          },
        });
        this.revision++;
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('algorithm-run-error'),
        });
      }
      this.algorithmSelected = '';
    },

    async fetchFilters() {
      const stats = await noteApi.get(
        `/api/project/${this.project.id}/bounds/imageinstance.json`
      );

      this.maxWidth = DEFAULT_BOUNDS_WIDTH[1] = Math.max(100, stats.width.max);
      if (!JSON.parse(localStorage.getItem(this.idProject + '_boundsWidth'))) {
        this.boundsWidth[1] = this.maxWidth;
      }
      this.maxHeight = DEFAULT_BOUNDS_HEIGHT[1] = Math.max(
        100,
        stats.height.max
      );
      if (!JSON.parse(localStorage.getItem(this.idProject + '_boundsHeight'))) {
        this.boundsHeight[1] = this.maxHeight;
      }
      this.maxNbUserAnnotations = DEFAULT_BOUNDS_USER_ANNOTATIONS[1] = Math.max(
        100,
        stats.countImageAnnotations.max
      );
      if (
        !JSON.parse(
          localStorage.getItem(this.idProject + '_boundsUserAnnotations')
        )
      ) {
        this.boundsUserAnnotations[1] = this.maxNbUserAnnotations;
      }
      this.maxNbJobAnnotations = DEFAULT_BOUNDS_JOB_ANNOTATIONS[1] = Math.max(
        100,
        stats.countImageJobAnnotations.max
      );
      if (
        !JSON.parse(
          localStorage.getItem(this.idProject + '_boundsJobAnnotations')
        )
      ) {
        this.boundsJobAnnotations[1] = this.maxNbJobAnnotations;
      }
      this.maxNbReviewedAnnotations = DEFAULT_BOUNDS_REVIEWED_ANNOTATIONS[1] = Math.max(
        100,
        stats.countImageReviewedAnnotations.max
      );

      if (
        !JSON.parse(
          localStorage.getItem(this.idProject + '_boundsReviewedAnnotations')
        )
      ) {
        this.boundsReviewedAnnotations[1] = this.maxNbReviewedAnnotations;
      }

      this.availableFormats = stats.format.list;

      const uniqueVendors = this.getUniqueValues(stats.mimeType.list);

      this.availableVendors = uniqueVendors.map((mime) => {
        const vendor = vendorFromMime(mime);
        return {
          value: mime || 'null',
          label: vendor
            ? `${vendor.name} (${mime})`
            : `${this.$t('unknown')} (${mime})`,
        };
      });

      this.availableStatus = [
        { value: 3, label: 'Success' },
        { value: 2, label: 'Running' },
        { value: 4, label: 'Failed' },
        { value: 8, label: 'Canceled' },
        { value: 0, label: 'None' },
      ];

      this.availableMagnifications = stats.magnification.list.map((m) => {
        return {
          value: m || 'null',
          label: m || this.$t('unknown'),
        };
      });

      const uniqueResolutions = this.getUniqueValues(stats.resolution.list);
      this.availableResolutions = uniqueResolutions.map((resolution) => {
        return {
          value: resolution || 'null',
          label: resolution
            ? `${resolution.toFixed(6)} ${this.$t('um-per-pixel')}`
            : this.$t('unknown'),
        };
      });
    },

    // Method to get sorted unique values for future use
    getUniqueValues(array) {
      const validValues = [];
      let value;
      for (let i = 0; i < array.length; i++) {
        value = array[i];
        if (array[i] != null && !isNaN(array[i])) {
          value = array[i];
        }
        if (validValues.indexOf(value) === -1) {
          validValues.push(value);
        }
      }
      return validValues.sort();
    },

    async fetchTags() {
      const results = await noteApi.get('/api/tag.json?max=0&offset=0');
      this.availableTags = [
        { id: 'null', name: this.$t('no-tag') },
        ...results.collection,
      ];
    },

    async refreshData() {
      try {
        await Promise.all([this.fetchFilters()]);
        this.request = this.fetchImages();
      } catch (error) {
        console.log(error);
        this.error = true;
      }
    },

    isTabDisplayed(tab) {
      return this.isAdmin || this.configUI[`project-${tab}-field`];
    },

    /** @param {Array<{ id: number }>} checkedRows */
    async triggerDownload(checkedRows) {
      const imageIds = checkedRows.map((image) => image.id);

      downloadImages(this.idProject, imageIds)
        .then(() => {
          this.$notify({
            text: this.$t('download-links-sent'),
          });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: this.$t('unexpected-error-info-message'),
          });
        });
    },

    onReviewCanceled(image) {
      image.reviewStart = null;
      image.reviewStop = null;
      image.reviewUser = null;
    },

    copyToProject(images) {
      this.$buefy.modal.open({
        component: () => import('./ListImagesCloneModal.vue'),
        props: {
          images: images,
        },
        parent: this,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    resetFiltersToDefault() {
      // set values to defaults
      this.searchString = DEFAULT_SEARCH_STRING;
      this.selectedFormats = [];
      this.selectedVendors = [];
      this.selectedStatus = [];
      this.selectedMagnifications = [];
      this.selectedResolutions = [];
      this.selectedTags = [];
      this.boundsWidth = DEFAULT_BOUNDS_WIDTH;
      this.boundsHeight = DEFAULT_BOUNDS_HEIGHT;
      this.boundsUserAnnotations = DEFAULT_BOUNDS_USER_ANNOTATIONS;
      this.boundsJobAnnotations = DEFAULT_BOUNDS_JOB_ANNOTATIONS;
      this.boundsReviewedAnnotations = DEFAULT_BOUNDS_REVIEWED_ANNOTATIONS;

      this.currentPage = DEFAULT_CURRENT_PAGE;
      this.perPage = DEFAULT_PER_PAGE;
      this.sortBy = DEFAULT_SORT_BY;
      this.sortDirection = DEFAULT_SORT_DIRECTION;
      // update local storage
      localStorage.removeItem(this.idProject + '_currentPage');
      localStorage.removeItem(this.idProject + '_sortBy');
      localStorage.removeItem(this.idProject + '_sortDirection');
      localStorage.removeItem(this.idProject + '_searchString');
      localStorage.removeItem(this.idProject + '_selectedFormats');
      localStorage.removeItem(this.idProject + '_selectedVendors');
      localStorage.removeItem(this.idProject + '_selectedStatus');
      localStorage.removeItem(this.idProject + '_selectedMagnifications');
      localStorage.removeItem(this.idProject + '_selectedResolutions');
      localStorage.removeItem(this.idProject + '_selectedTags');
      localStorage.removeItem(this.idProject + '_boundsWidth');
      localStorage.removeItem(this.idProject + '_boundsHeight');
      localStorage.removeItem(this.idProject + '_boundsUserAnnotations');
      localStorage.removeItem(this.idProject + '_boundsJobAnnotations');
      localStorage.removeItem(this.idProject + '_boundsReviewedAnnotations');
    },

    isColumnShown(columnLabel) {
      const column = this.imageColumns.find((c) => c.label === columnLabel);
      return column && column.visible && column.selectable;
    },
  },
  i18n: {
    messages: {
      en: {
        'project-archived-notice':
          'This project was archived and is currently being restored. Please allow some time for images to become available.',
      },
      fr: {
        'project-archived-notice':
          'Ce projet a été archivé et est actuellement en cours de restauration. Veuillez patienter un certain temps avant que les images ne soient disponibles.',
      },
    },
  },
};
</script>

<style scoped>
>>> .table td,
>>> .table th {
  vertical-align: middle;
}
</style>
